/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-07-03",
    versionChannel: "nightly",
    buildDate: "2023-07-03T00:20:04.806Z",
    commitHash: "768eaa1a01e7a5a571cd5e99fb560fa37a02a1dc",
};
